import 'css/fonts.css';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';

// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { LocationProvider } from '@reach/router';

import { defaultTheme } from 'styles/theme';
// import { useTheme } from 'styles/theme';

import client from 'client';
import { contentSelector, isBrowser } from 'services';

// This file needs to be in the /src folder otherwise it wil
// result in a Loading (StaticQuery).

export const wrapRootElement = ({ element }) => {
  return (
    <LocationProvider>
      <QueryClientProvider client={client}>
        <ThemeProvider theme={defaultTheme}>
          {element}
        </ThemeProvider>
      </QueryClientProvider>
    </LocationProvider>
  );
};

export default wrapRootElement;
