import 'styled-components';

import logo from 'images/logo.png';

export const defaultTheme = {
  colors: {
    primary: '#73C492',
    primaryDeep: '#242422',
    secondary: '#89CDA3',
    black: '#1D191F',
    white: Object.assign('#FFFFFF', {
      off: '#F7F7F7',
      border: '#FFFFFF20',
      dark: '#ECEEF0',
    }),
    blue: Object.assign('#40639D', {
      deep: '#0A4E8B',
    }),
    gray: Object.assign('#606060', {
      neutral: '#8C8A8D',
      border: '#CFCECF',
      soft: '#ededed',
      disabled: '#F9F9F9',
      background: '#F7F7F7',
      dark: '#606060',
      tableBorder: '#00000029',
    }),
    orange: '#EE7522',
    pink: '#EA536D',
    darkBlue: '#14152D',
    red: '#EB3456',
  },

  ui: {
    borderRadius: {
      card: '32px',
      badge: '12px',
      button: '16px',
      buttonSmall: '8px',
      buttonToggle: '6px',
      circle: '100%',
    },
    shadows: Object.assign('0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.4)', {
      primary: '0px 8px 24px -6px rgba(0, 153, 255, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.4)',
    }),
  },

  fonts: {
    noto:  'sans-serif',
    BrokenBrush: '\"Brokenbrush\", sans-serif',
    terminalGrotesque: '\"terminal grotesque\", sans-serif',
  },
  logo: logo,
};
