import * as i from 'types';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('en');
dayjs.extend(customParseFormat);

export const monthHourDateFormat = 'DD MMMM - HH:mm';
export const defaultDateFormat = 'DD-MM-YYYY';
export const serverDateFormat = 'YYYY-MM-DD';

export const formatDate = (date: i.DateType, format?: string, inputFormat?: string): string => {
  if (!date) throw new Error('No date provided to format');
  return dayjs(date, inputFormat).format(format || defaultDateFormat);
};

export const getDateDifference = (date: string) => {
  const date1 = dayjs().diff(date, 'hours');
  const days = Math.floor(date1 / 24);
  const hours = date1 % 24;

  return {
    days,
    hours,
  };
};
